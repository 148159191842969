footer{
    box-sizing: border-box;
    width: 100%;
    height: 30vh;
    margin-top: 10vh;
    background-color: rgba(34, 34, 34, 0.623);
    border-top: 8px ridge rgba(34, 34, 34, 0.623);
    padding: 1%;
    color: var(--white-accent);
    font-size: 1.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

footer img{
    height: 3rem;
    margin: 1rem;
}

footer p{
    line-height: 2.5rem;
}

footer a{
    color: var(--white-accent);
    position: relative;
}   

footer p a::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 0.13rem;
    top: 100%;
    background-color: var(--white-accent);
    transform: scale(0);
    transition: 0.15s;
}

footer a:hover::before{
    transform: scale(1);
}

footer span{
    margin-right: 1rem;
}

.footer-logo{
    height: 8rem;
    margin-top: -1rem;
}