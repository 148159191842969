.navbar {
  z-index: 100;
  position: sticky;
  top: 0;
  width: 100%;
  height: 4rem;
  background-color: var(--dark-background);
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-end;
  justify-items: stretch;
  box-shadow: 0px 3px var(--white-accent)
}

.vw{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .leftvw{
    width: 100%;
    height: 1px;
    display: flex;
    justify-content: space-between;
  }
  
  .logo{
    margin-top: 110px;
    margin-left: 100px;
    height: 100px;
    z-index: 200;
  }
  
  .demo {
    border-radius: 50%;
    outline: var(--white-accent) groove 0.8vh;
    height: 150px;
    margin-top: 120px;
    margin-right: -100px;
  }

  .leftvw a{
    position: absolute;
    top: -8rem;
  }
  
  .navbar ul{
    list-style: none;
    display: flex;
  }
  
  .navbar a {
    position: relative;
    font-family: 'coolvetica-big';
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-right: 3vh;
    color: var(--white-accent);
  }

  .active::before, .vw a::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 0.15rem;
    top: 100%;
    background-color: var(--white-accent);
    transform: scale(0);
    transition: 0.15s;
  }

  .vw .active::before, .vw a:hover::before{
    transform: scale(1);
  }