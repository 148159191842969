h4{
  margin:0;
}

#mint{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
  }
  
.form {
  padding: 3%;
  background-image: url("../images/mint-pannel.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: calc(60vh / 56 * 100);
  height: 60vh;
  margin-bottom: -8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mintButtons{
  width: 100%;
  height:10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2%;
}

.container{
  position: relative;
  width: 40%;
  padding-bottom: 4.82%;
}

.button{
  position: absolute;
  height: 100%;
  width: 100%;
  border:0;
  background-color: transparent;
  background-image: url('../images/buttonbg.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  font-family: 'coolvetica-big';
  text-transform: uppercase;
  font-size: x-large;
}

.button:active {
  background-image: url('../images/buttonbg_clicked.png');
}

.rwaste{
  pointer-events: none;
  position: absolute;
  top: -20%;
  left: center;
  width: 100%;
  height: 170%;
  background-image: url("../images/rwaste.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.quantity{
  -webkit-appearance: none;
  width: 40%;
  height: 10px;
  background-color: var(--red_shadow);
  border: 6px ridge var(--red);
  border-radius: 6px;
  box-shadow: -4px 4px 5px 6px var(--dark_grey);
}

.quantity::-webkit-slider-thumb{
  position: relative;
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url('../images/slider_thumb.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 2rem;
  width: 2rem;
}

.priceDisplay{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: x-large;
  font-weight: bolder;
  margin-left: 10%;
}

.prices{
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.prices div{
  width: 40%;
  display: flex;
  justify-content: center;
  background-color: var(--dark_grey);
  outline: .4rem ridge var(--red);
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  color: var(--white-accent);
  box-shadow: -4px 4px 5px 6px var(--dark_grey);
}

.middle{
 margin-right: 10%;
}

.currency{
  height: 60px;
  width: auto;
  vertical-align: middle;
}

.left{
  background-color: var(--dark_grey);
  outline: .4rem ridge var(--red);
  border-radius: 10px;
  padding: 20px;
  color: var(--white-accent);
  box-shadow: -4px 4px 5px 6px var(--dark_grey);
}

.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.count {
  font-size: 90px;
  margin-top: 5%;
  margin-bottom: 0;
}

.bio{
    font-size: 1.5em;
    text-align: center;
    margin-top: 8%;
}