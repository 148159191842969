.modalBG{
  position: fixed; 
  z-index: 1000; 
  left: 0;
  top: 0;
  width: 100%;
  height: 130%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: grid;
  place-items: center;
  backdrop-filter: blur(5px);
  animation-name: entry;
  animation-duration: 0.2s;
}

.none{
  display: none;
}

.rwasteModal{
  margin-top: -35vh;
  width: 60vw;
  height: 40vh;
  background-color: var(--grey);
  outline: 0.6rem ridge var(--red);
  border-radius: 10px;
  z-index: 200;
  position: fixed;
  padding: 0 2rem;
  display: grid;
  place-items: center;
}

.rwasteModal img{
  position: absolute;
  width: 20rem;
  top: -4.5rem;
  left: 5rem;
}

.close{
  position: absolute;
  left: 95%;
  top: 0;
  color: var(--white-accent);
  font-size: 5rem;
}

.close:hover,
.close:focus {
  color: var(--red);
  text-decoration: none;
  cursor: pointer;
}

.rwasteModal div{
  text-align: center;
  font-size: 2rem;
}

.rwasteModal .button{
  margin-left: -50%;
}

@keyframes entry {
  from {top: -30%; opacity: 0}
  to {top: 0; opacity: 1}
}