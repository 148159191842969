@import-normalize;
@font-face {
  font-family: 'coolvetica-big';
  src: url(.\font\coolvetica_rg.otf);
}
@font-face {
  font-family: 'coolvetica';
  src: url(.\font\coolvetica_condensed_rg.otf);
}

body, html, button {
  --white-accent: #cbdbfc;
  --dark-background: #221f34;
  --red: #d42f3e;
  --red_shadow: #7d2323;
  --dark_grey: #393841;
  --grey: #4c4b56;
  font-family: 'coolvetica';
  background-color: var(--dark-background);
  scroll-behavior: smooth;
  width: 100%;
  letter-spacing: 0.1em;
}

#main{
  display: flex;
  flex-direction: column;
}

.gradient{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg,transparent 50%,var(--dark-background));
}

.home {
  width: 100%;
  height: 95vh;
  background-image: url('./images/stars_background.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.animatedLogo{
  max-width: 800px;
  margin-bottom: 1%;
}

.chevron{
  width: 150px;
  opacity: 0.8;
}