#creator{
  width: 100%;
  margin-top: 10vh;
  display: grid;
  place-items: center;
}

.creator{
  position: relative;
  width: 50%;
  min-height: 100vh;
  min-width: 40rem;
  color: var(--white-accent);
  font-size: 1.5rem;
}

.creator .center{
  display: grid;
  place-items: center;
}

.portrait h3::first-letter, .creator h2::first-letter{
  color: var(--red);
}

.creator h2{
  margin-top: 12rem;
  font-size: 5rem;
}

.creator p{
  text-align: justify;
}

.portrait{
  margin-top: 3rem;
  margin-right: 1rem;
  float: left;
  width: fit-content;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.portrait img{
  width: 15rem;
  border-radius: 50%;
}

.portrait h3{
  margin: 0;
}