#roadmap{
    width: 100%;
    margin-top: 10vh;
    background-image: url('./images/montain_background.gif');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(var(--dark-background),transparent 30%, transparent 70%, var(--dark-background));
    background-color: rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg > div{
    width: 50%;
    min-width: 40rem;
    background-color: rgba(34, 34, 34, 0.623);
    border-radius: 15px;
    border: 8px ridge rgba(34, 34, 34, 0.623);
    padding: 1%;
    color: var(--white-accent);
}

.frame{
    position: relative;
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.roadmap-title{
    width: 100%;
    top: -8rem;
    position: absolute;
    font-size: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    position: relative;
    width: 80%;
    background-color: var(--dark_grey);
    border-radius: 20px;
    margin: 20px;
    display: grid;
    color: var(--white-accent);
}

.content p{
    font-size: 1.5em;
    margin: 1rem 2rem;
}

.content div{
    font-family: 'coolvetica-big';
    position: absolute;
    left: -20%;
    background-color: var(--dark_grey);
    box-shadow: 0 0 0 5px var(--red_shadow);
    border-radius: 50%;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
}

#whatnext, #project {
    margin-top: 2vh;
    position: relative;
}

#project h2, #whatnext h2{
    font-size: 2.5em;
    margin: 0;
    cursor: pointer;
    width: max-content;
}

h2 span{
    font-size: 1.3em;
    color: var(--red);
}

#whatnext >div, #project > div{
    margin-left: 2rem;
    font-size: 1.5em;
    transform: scaleY(0);
    transform-origin: top;
    transition: .5s;
}

#whatnext .expanded, #project .expanded{
    transform: scaleY(1);
}

#whatnext p, #project p{
    display: none;
}

#whatnext .expanded p, #project .expanded p{
    display: block;
}

#project h2::before, #whatnext h2::before{
    content: '>';
    margin-right: 1.5rem;
    font-size: 3.5rem;
    font-weight: 900;
    display: inline-block;
    transition: 0.3s;
}

#project h2.expanded::before, #whatnext h2.expanded::before{
    transform: rotate(90deg);
}